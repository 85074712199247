import React, { useEffect, useMemo, useState } from "react";
import { styled } from "goober";

import { readPath } from "@/__main__/get-data.mjs";
import { GAME_SYMBOL_LOL } from "@/game-lol/definition-symbol.mjs";
import { useOverlaySettings } from "@/library/actions.mjs";
import { useSimulationEngine } from "@/library/hooks.mjs";
import {
  OverlayContainer,
  OverlayContent,
  OverlayContentItems,
  OverlayHeader,
} from "@/library/SimulatedOverlays.style.jsx";
import SimpleLineChart from "@/shared/SimpleLineChart.jsx";
import useTFunction from "@/util/use-t-function.mjs";

const SIMULATED_BENCHMARKING_CONFIG = {
  csSelectKey: ["benchmarking", "csPerMinute"],
  features: {
    cs: {
      key: ["benchmarking", "isCsPerMinuteEnable"],
      label: ["overlays:lol.benchmarking.simulated.csPerMin", "CSM"],
      simulationSpec: {
        values: [5.0, 7.1, 8.2, 6.9],
        type: "decimal",
      },
      compare: 6.2,
    },
    gold: {
      key: ["benchmarking", "isGoldPerMinuteEnabled"],
      label: ["overlays:lol.benchmarking.simulated.goldPerMin", "GPM"],
      simulationSpec: {
        startValue: 312,
        direction: "up",
        maxValue: 500,
        minValue: 352,
        delta: 7,
        updateInterval: 1,
        type: "integer",
      },
      compare: 528,
    },
    kills: {
      key: ["benchmarking", "isKillParticipationEnabled"],
      label: ["overlays:lol.benchmarking.simulated.killParticipation", "KP"],
      simulationSpec: {
        startValue: 28,
        direction: "up",
        maxValue: 34,
        updateInterval: 3,
        minValue: 0,
        delta: 3,
        type: "percentage",
      },
      compare: 30,
    },
    kda: {
      key: ["benchmarking", "isKdaEnabled"],
      label: ["overlays:lol.benchmarking.kda.title", "KDA"],
      simulationSpec: {
        startValue: 2.4,
        delta: 1.2,
        maxValue: 9.2,
        minValue: 0.8,
        direction: "up",
        updateInterval: 2,
        type: "decimal",
      },
      compare: 2,
    },
    championLevel: {
      key: ["benchmarking", "isChampionLevelEnabled"],
      label: ["overlays:lol.benchmarking.simulated.level", "LVL"],
      simulationSpec: {
        startValue: 5,
        direction: "up",
        maxValue: 17,
        minValue: 5,
        delta: 3,
        type: "integer",
      },
      compare: 12,
    },
  },
} as const;

const SimulatedBenchmarkingOverlay = () => {
  const t = useTFunction();
  const settings = useOverlaySettings(GAME_SYMBOL_LOL);

  const { simulatedValues } = useSimulationEngine({
    simulationSchema: Object.values(
      SIMULATED_BENCHMARKING_CONFIG.features,
    ).reduce((acc, feature) => {
      acc[feature.key.toString()] = { ...feature.simulationSpec };
      return acc;
    }, {}),
    tickIntervalInMs: 1000,
    numberOfTicks: 10,
  });

  const data = useMemo(() => {
    if (!settings) return;
    if (!simulatedValues) return;

    const { features, csSelectKey } = SIMULATED_BENCHMARKING_CONFIG;

    return {
      features: Object.keys(features).map((featureKey) => {
        return {
          ...features[featureKey],
          type: features[featureKey].simulationSpec.type,
          value: simulatedValues[features[featureKey].key],
          show: readPath(features[featureKey].key, settings),
          displayType:
            featureKey === "cs" ? readPath(csSelectKey, settings) : "legacy",
        };
      }),
    };
  }, [settings, simulatedValues]);

  if (!data) return; // TEMP

  return (
    <OverlayContainer className="relative">
      <OverlayHeader />
      <OverlayContent className="w-full">
        <OverlayContentItems>
          {data.features.map(({ show, label, displayType, ...rest }, i) => (
            <HideableDisplayOption key={i} className={show ? "show" : "hide"}>
              <span className="type-caption">{t(label)}</span>
              <div className="flex align-baseline gap-sp-1">
                {(displayType === "basic" || displayType === "legacy") && (
                  <Legacy {...rest} />
                )}
                {displayType === "detailed" && <Detailed {...rest} />}
              </div>
            </HideableDisplayOption>
          ))}
        </OverlayContentItems>
      </OverlayContent>
    </OverlayContainer>
  );
};

export default SimulatedBenchmarkingOverlay;

interface BenchmarkingProps {
  type: string;
  value: number;
  compare: number;
}

// TODO: i18n - non-compliant formatter; but if in-game overlay is the same, then it's fine?
const formatValue = (value: number, type: string) => {
  return `${type === "decimal" ? value.toFixed(1) : value}${
    type === "percentage" ? "%" : ""
  }`;
};

const Legacy = ({ type, value, compare }: BenchmarkingProps) => {
  return (
    <>
      <LegacyValue
        $textColor={compare < value ? "var(--turq)" : "#F6676B"}
        className="type-body2-form--active"
      >
        {formatValue(value, type)}
      </LegacyValue>
      <span className="slash type-mini color-shade1">/</span>
      <LegacyCompare>
        <span className="type-mini color-shade1">
          {formatValue(compare, type)}
        </span>
      </LegacyCompare>
    </>
  );
};

const LegacyValue = styled("span")<{
  $textColor: string;
}>`
  --color: ${({ $textColor }) => $textColor};

  color: var(--color);
`;

const LegacyCompare = styled("div")`
  display: inline-flex;
  gap: var(--sp-1_5);
  width: 2ch;
`;

const Detailed = ({ type, value, compare }: BenchmarkingProps) => {
  const [data, setData] = useState([
    {
      x: 1,
      y: 13.0,
    },
    { x: 2, y: 16.2 },
    { x: 3, y: 15.0 },
    { x: 4, y: 17.0 },
    { x: 5, y: 13.0 },
    { x: 2, y: 16.2 },
    { x: 6, y: 15.0 },
    {
      x: 7,
      y: 17.0,
    },
  ]);

  useEffect(() => {
    setData((data) => {
      data.shift();
      return [...data, { x: data.length + 1, y: value }];
    });
  }, [value]);

  const chartConfig = {
    xAxisConf: {
      visible: false,
    },
    yAxisConf: {
      visible: true,
      ticks: 3,
      tickRenderer: () => {
        return null;
      },
    },
  };

  const color =
    compare < value
      ? "var(--turq)"
      : compare === value
        ? "var(--shade1)"
        : "var(--red)";

  return (
    <>
      <SimpleLineChart
        data={data}
        xField="x"
        yField="y"
        height={20}
        width={65}
        showGridLines
        showOneGridLine
        showOnlyLastPoint
        circleRadius={2}
        gridLinesColor="var(--shade0)"
        color="var(--turq)"
        xAxisConf={chartConfig.xAxisConf}
        yAxisConf={chartConfig.yAxisConf}
      />
      <div>
        <span
          style={{ color, paddingLeft: "var(--sp-2)" }}
          className="type-body2-form--active"
        >
          {formatValue(compare, type)}
        </span>
      </div>
    </>
  );
};

const HideableDisplayOption = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--sp-3);
  padding: var(--sp-0_5) 0;
  transition: var(--transition);
  transform: translateY(0);

  &.hide {
    transform: translateY(var(--sp-2)) scale(0.95);
    filter: blur(2px);
    height: 0;
    opacity: 0;
    margin-top: 0;
  }

  .tag {
    width: var(--sp-14);
  }
`;
